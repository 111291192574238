<template>
  <div class="row">
    <Toast ref="mensajeToast"></Toast>
    <Alerts ref="alert"></Alerts>
    <ModalPDF ref="visorPDF"></ModalPDF>
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Reporte de Estudiantes por materia</h5>
          <form ref="frmRptEstudiantesMaterias" novalidate>
            <div class="card-text">
              <div class="row">
                <div class="col-2">
                  <label class="form-label" for="gestion">Gestión:</label>
                  <select class="form-control pl-1 pr-1" id="gestion"
                          v-model="rptEstudiantesMaterias.gestion"
                          @change="actualizaFacultades()" required>
                    <option value="" selected>:: SELECCIONAR ::</option>
                    <template v-for="(option,key) in optEstudiantesMaterias.gestiones">
                      <optgroup :label="key">
                        <option v-for="suboption in option" :value="suboption.value">
                          {{ suboption.label }}
                        </option>
                      </optgroup>
                    </template>
                  </select>
                </div>
                <div class="col-3">
                  <CSelect
                      required="required"
                      :options="optEstudiantesMaterias.facultades"
                      :plain="true"
                      :value.sync="rptEstudiantesMaterias.facultad_id"
                      @change="rptEstudiantesMaterias.carrera_id='';optEstudiantesMaterias.semestres=[];"
                      label="Facultad"
                  >
                  </CSelect>
                </div>
                <div class="col-3">
                  <label for="alumnoCarrera">Carrera</label>
                  <select id="alumnoCarrera"
                          v-model="rptEstudiantesMaterias.carrera_id" class="form-control"
                          @change="actualizaSemestres()" required="required">
                    <option value="">:: SELECCIONAR ::</option>
                    <option v-for="option in optEstudiantesMaterias.carreras[rptEstudiantesMaterias.facultad_id]"
                            :value="option.value">{{ option.label }}
                    </option>
                  </select>
                </div>
                <div class="col-2">
                  <label for="semestre">Semestre:</label>
                  <select id="semestre"
                          v-model="rptEstudiantesMaterias.semestre" class="form-control"
                  >
                    <option value="0">:: TODOS ::</option>
                    <option v-for="option in optEstudiantesMaterias.semestres"
                            :value="option.value">{{ option.label }}
                    </option>
                  </select>
                </div>
                <div class="col-2">
                  <label for="notas">Incluir notas</label>
                  <input type="checkbox" v-model="rptEstudiantesMaterias.notas" class="form-control" id="notas"/>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 text-center mt-3">
                <button type="button" class="btn btn-success" @click="rptBtnEstudiantesMaterias()">
                  <font-awesome-icon icon="fa-file-excel"/>
                  Ver reporte
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Toast from "@/views/notifications/Toast";
import Alerts from "@/views/notifications/Alerts";
import ModalPDF from "@/views/notifications/ModalPDF";

var modalPdf;

export default {
  name: 'Alumnos',
  components: {
    ModalPDF,
    Alerts,
    Toast,
  },
  data: () => {
    return {
      sede: localStorage.sedeSeleccionada,
      rptEstudiantesMaterias: {
        sede: localStorage.sedeSeleccionada,
        gestion: '',
        facultad_id: '',
        carrera_id: '',
        semestre: '',
        notas: false
      },
      optEstudiantesMaterias: {
        gestiones: [],
        facultades: [],
        carreras: [],
        semestres: []
      }
    }
  },
  methods: {
    rptMatriculadosGestionGenerosEdades: function () {
      modalPdf.$refs.alert.show("Generando Reporte");
      axios.post(this.$apiAdress + '/api/reporte/matriculagestiongeneroedad?token=' + localStorage.getItem("api_token"),
          {sede: localStorage.sedeSeleccionada},
          {
            responseType: 'blob',
          }
      )
          .then((response) => {
            const url = URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute(
                'download',
                'rptMatriculadosGestionGenerosEdades-' + (new Date().toLocaleDateString()) + '.xlsx'
            )
            document.body.appendChild(link)
            link.click()
            modalPdf.$refs.alert.hide();
          })
          .catch(function (error) {
            modalPdf.$refs.mensajeToast.mostrarError(error,modalPdf);
          });
    },
    rptBtnEstudiantesMaterias: function () {
      if (!modalPdf.$refs.frmRptEstudiantesMaterias.checkValidity()) {
        modalPdf.$refs.frmRptEstudiantesMaterias.classList.add('was-validated');
      } else {
        modalPdf.$refs.alert.show("Generando Reporte");
        axios.post(this.$apiAdress + '/api/reporte/estudiantespormateria?token=' + localStorage.getItem("api_token"),
            this.rptEstudiantesMaterias,
            {
              responseType: 'blob',
            }
        )
            .then((response) => {
              const url = URL.createObjectURL(new Blob([response.data]))
              const link = document.createElement('a')
              link.href = url
              link.setAttribute(
                  'download',
                  'estudiantespormateria-' + (new Date().toLocaleDateString()) + '.xlsx'
              )
              document.body.appendChild(link)
              link.click()
              modalPdf.$refs.alert.hide();
            })
            .catch(function (error) {
              modalPdf.$refs.mensajeToast.mostrarError(error,modalPdf);
            });
      }
    },
    actualizaFacultades: function () {
      axios.get(this.$apiAdress + '/api/reporte/' + modalPdf.sede + '/' + modalPdf.rptEstudiantesMaterias.gestion + '/falcultadesconestudiantes?token=' + localStorage.getItem("api_token"))
          .then((response) => {
            let valorPorDefecto = [{value: '', label: ':: SELECCIONAR ::'}];
            modalPdf.optEstudiantesMaterias.facultades = valorPorDefecto.concat(response.data.facultades);
            modalPdf.optEstudiantesMaterias.carreras = response.data.carreras;
          })
          .catch(function (error) {
            modalPdf.$refs.mensajeToast.mostrarError(error,modalPdf);
            modalPdf.optEstudiantesMaterias.facultades = [];
            modalPdf.optEstudiantesMaterias.carreras = []
          });
    },
    actualizaSemestres: function () {
      axios.get(this.$apiAdress + '/api/reporte/' + modalPdf.sede + '/' + modalPdf.rptEstudiantesMaterias.gestion + '/' + modalPdf.rptEstudiantesMaterias.carrera_id + '/semestresconestudiantes?token=' + localStorage.getItem("api_token"))
          .then((response) => {
            modalPdf.rptEstudiantesMaterias.semestre = 0;
            modalPdf.optEstudiantesMaterias.semestres = response.data.semestres;
          })
          .catch(function (error) {
            console.log(error);
            modalPdf.rptEstudiantesMaterias.semestre = '';
            modalPdf.optEstudiantesMaterias.semestres = []
          });
    }
  },
  mounted: function () {
    modalPdf = this;
    axios.get(this.$apiAdress + '/api/reporte/' + modalPdf.sede + '/gestionconestudiantes?token=' + localStorage.getItem("api_token"))
        .then((response) => {
          modalPdf.optEstudiantesMaterias.gestiones = response.data.gestionoptions;
        })
        .catch(function (error) {
          modalPdf.$refs.mensajeToast.mostrarError(error,modalPdf);
        });
  }
}
</script>